@import "../../App.scss";

.contact {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contact-content {
  margin: auto;
  max-width: 600px;
  font-size: $p-font-size;
  padding: 120px 15px 120px 15px;
  line-height: 30px;
}

.contact-links {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 0px;
  display: flex;
  justify-content: center;
  opacity: 0.8;
}

.instagram {
  content: url("../../assets/instagram.svg");
  margin-right: 20px;
}

.facebook {
  content: url("../../assets/facebook.svg");
  margin-left: 20px;
}
