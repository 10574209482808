@import "../../App.scss";

.home {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.thumbnail-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1440px;
  margin-top: 150px;
  margin: 100px 7.5px 100px 7.5px;
}
