@import "../../App.scss";

footer {
  width: 100%;
  text-align: center;
  padding: 25px 0 12.5px 0;
  font-size: 14px;
}

footer a {
  margin-left: 5px;
  position: relative;
}

footer a::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: -2px;
  left: 0;
  background: $primary-color;
  opacity: 0.4;
}
