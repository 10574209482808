@import "../../App.scss";

.top-to-btm {
  position: relative;
}

.icon-position {
  position: fixed;
  bottom: 6%;
  right: 4%;
  z-index: 20;
}

.icon-style {
  background-color: transparent;
  border: 2px solid rgba(0, 0, 0, .6);
  color: $primary-color;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 23px;
  text-align: center;
  font-weight: bolder;
}
