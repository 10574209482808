@import "../../App.scss";

.thumbnail {
  width: 100%;
  height: 100%;
  min-height: 450px;
  max-width: 450px;
  max-height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 7.5px;
  cursor: pointer;
  position: relative;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
}

.thumbnail:hover {
  opacity: 0.75;
}

.thumbnail-title {
  font-size: $h1-font-size;
  z-index: 1;
  color: #fff;
}
