@import "../../App.scss";

.video-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 15px;
  margin-bottom: 75px;
}

.video-content .video-title {
  margin-bottom: 20px;
}

.video-content iframe {
  border: none;
  width: 40%;
  height: 400px;
  min-width: 300px;
  margin: 0 15px;
}

.video-content .video-text {
  margin: 15px;
  width: 50%;
}

.video-content .video-credits {
  font-size: $p-font-size;
}

@media screen and (max-width: 749px) {
  .video-content iframe {
    width: 100%;
  }

  .video-content .video-text {
    width: 100%;
  }
}
